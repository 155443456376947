<template>
  <EntitySingle
    :config="config"
    :entity.sync="entity"
    :loaded.sync="entityLoaded"
    :submit-callback="submitCallback"
  >
    <b-card v-if="entityLoaded">
      <b-tabs pills>
        <!-- Tab: Participant -->
        <b-tab active>
          <template #title>
            <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Account') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="participantFormFields"
            allow-autofocus
          />
        </b-tab>

        <!-- Tab: Company -->
        <b-tab>
          <template #title>
            <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
            <span class="d-none d-sm-inline">{{ $t('Company') }}</span>
          </template>
          <EntityEditSection
            :module="config.module"
            :entity.sync="entity"
            :form-fields="companyFormFields"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </EntitySingle>
</template>

<script>
import { BTab, BTabs, BCard } from 'bootstrap-vue'
import { translationUtils } from '@core/mixins/ui/translations'
import EntityEditSection from '@/layouts/entity/EntityEditSection.vue'
import EntitySingle from '@/layouts/entity/EntitySingle.vue'

import storeModule from '../userRequestStoreModule'

export default {
  components: {
    EntityEditSection,
    EntitySingle,
    BTab,
    BTabs,
    BCard,
  },
  mixins: [translationUtils],
  data() {
    return {
      entity: {},
      entityLoaded: false,
      countryOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'user-requests',
        endpoint: 'user-requests',
        route: 'user-requests',
        title: {
          single: this.$t('User Request'),
          plural: this.$t('User Requests'),
        },
      }
    },

    participantFormFields() {
      return [
        {
          id: 'firstName',
          type: 'text',
          parent: 'participant',
          label: this.$t('First Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'lastName',
          type: 'text',
          parent: 'participant',
          label: this.$t('Last Name'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'email',
          type: 'email',
          parent: 'participant',
          label: this.$t('email'),
          required: true,
          rules: 'email',
          colSize: 3,
        },
      ]
    },
    companyFormFields() {
      return [
        {
          id: 'title',
          type: 'text',
          parent: 'company',
          label: this.$t('Company'),
          required: true,
          rules: 'min:3',
          colSize: 3,
        },
        {
          id: 'country',
          object: true,
          type: 'select',
          parent: 'company',
          label: this.$t('country'),
          options: this.countryOptions,
          required: true,
          colSize: 3,
        },
        {
          id: 'city',
          type: 'text',
          parent: 'company',
          label: this.$t('city'),
          required: true,
          colSize: 3,
        },
        {
          id: 'address',
          type: 'text',
          parent: 'company',
          label: this.$t('address'),
          required: true,
          colSize: 3,
        },
        {
          id: 'postalCode',
          type: 'text',
          parent: 'company',
          label: this.$t('postal code'),
          colSize: 3,
        },
        {
          id: 'telephone',
          type: 'text',
          parent: 'company',
          label: this.$t('telephone'),
          required: true,
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'mobile',
          type: 'text',
          parent: 'company',
          label: this.$t('mobile'),
          rules: 'phone',
          colSize: 3,
        },
        {
          id: 'birthDate',
          type: 'date',
          parent: 'company',
          label: this.$t('birth date'),
          colSize: 3,
        },
        {
          id: 'professionalTitle',
          type: 'text',
          parent: 'company',
          label: this.$t('professional title'),
          required: false,
          colSize: 3,
        },
        {
          id: 'companyName',
          type: 'text',
          parent: 'company',
          label: this.$t('company name'),
          colSize: 3,
        },
        {
          id: 'vatId',
          type: 'text',
          parent: 'company',
          label: this.$t('vat'),
          required: false,
          colSize: 3,
        },
        {
          id: 'vatOffice',
          type: 'text',
          parent: 'company',
          label: this.$t('vat office'),
          required: false,
          colSize: 3,
        },
        {
          id: 'bio',
          type: 'textarea',
          parent: 'company',
          label: this.$t('bio'),
          required: false,
          colSize: 6,
        },
        {
          id: 'map',
          type: 'map',
          parent: 'company',
          colSize: 12,
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCountries').then(response => {
      this.countryOptions = response.data
    })
  },
  methods: {
    submitCallback() {
      this.$router.push({ name: 'user-requests-list' })
    },
  },
}
</script>
